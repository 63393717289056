import { COLORS, ChipV2, Text } from "@asayinc/component-library";
import { CheckCircle, Error } from "@mui/icons-material";
import { Box, Stack, capitalize, useTheme } from "@mui/material";
import { useFormContext } from "react-hook-form";

import { CustomFormRadioGroup } from "src/components/molecules/CustomFormRadioGroup";
import { isContestedMeeting } from "src/pages/Communication/components/EventHeader/utils";
import { Proposal, ProxyCommunication } from "src/types/Communication";

interface IProposalCardProps {
  communication: ProxyCommunication;
  groupedProposals: Proposal[];
  isAttendingMeeting: boolean;
  isPassedCutoffDate: boolean;
  letterIndex?: number;
  proposal: Proposal;
}

export function ProposalCard({
  communication,
  groupedProposals,
  isAttendingMeeting,
  isPassedCutoffDate,
  letterIndex,
  proposal,
}: IProposalCardProps) {
  const theme = useTheme();
  const { getValues, setValue } = useFormContext();
  const {
    id,
    details,
    recommendation,
    voteChoices,
    groupMaximumVotes,
    contestingPartyRecommendation,
  } = proposal;

  const formValues = getValues();
  const currentValue = formValues[proposal.id];
  const hasSingleProposal = groupedProposals.length === 1;
  const proposalIds = groupedProposals.map((proposal) => proposal.id);
  const inFavors = proposalIds.filter(
    (proposalId) =>
      formValues[proposalId] === "for" || formValues[proposalId] === "yes"
  );
  const maxVoteError = inFavors.length > groupMaximumVotes;
  const isDissidentProposal = contestingPartyRecommendation === "for";

  const handleOnChange = () => {
    if (groupMaximumVotes && voteChoices.length < 3) {
      const updatedValues = getValues();
      // Note: We are assuming here we only have two positive choices
      // for and yes. If we decide to add more positive choices, we will
      // need to sync w/ the BE and update this logic.
      const inFavor = proposalIds.filter(
        (proposalId) =>
          updatedValues[proposalId] === "for" ||
          updatedValues[proposalId] === "yes"
      );
      const notInFavor = proposalIds.filter(
        (proposalId) =>
          updatedValues[proposalId] !== "for" &&
          updatedValues[proposalId] !== "yes"
      );
      if (inFavor.length === groupMaximumVotes) {
        // Since we only automatically set when we have two voteChoices
        // we are assuming that the first choice is the positive choice
        // and the second choice is the negative choice.
        const negativeChoice = voteChoices[1];
        notInFavor.forEach((p) => setValue(p, negativeChoice));
      }
    }
  };

  const validate = () => {
    if (groupMaximumVotes) {
      const updatedValues = getValues();
      const fors = proposalIds.filter(
        (proposalId) =>
          updatedValues[proposalId] === "for" ||
          updatedValues[proposalId] === "yes"
      );
      return fors.length <= groupMaximumVotes;
    }
    return true;
  };

  return (
    <Box
      sx={{
        borderBottom: "1px solid #E0E0E0",
        mt: hasSingleProposal ? 3 : 6,
        pb: 6,
        "&:first-of-type": { mt: hasSingleProposal ? 3 : 6 },
        "&:last-child": { borderBottom: "none", pb: 0 },
      }}
      id={`proposal-${id}`}
    >
      {communication && isContestedMeeting(communication) && (
        <ChipV2
          variant="secondary"
          selected
          label={isDissidentProposal ? "Dissident Proposal" : "Board Proposal"}
          sx={{
            mb: 3,
            fontWeight: 600,
            backgroundColor: isDissidentProposal
              ? "unset"
              : `${theme.palette.l1.main} !important`,
            color: isDissidentProposal
              ? "unset"
              : `${theme.palette.l6.main} !important`,
          }}
        />
      )}
      <Text sx={{ mb: 6 }} variant="body2">
        {letterIndex !== undefined &&
          !hasSingleProposal &&
          `${String.fromCharCode(65 + letterIndex)}. `}
        {details}
      </Text>
      <CustomFormRadioGroup
        name={id}
        isDisabled={isPassedCutoffDate || isAttendingMeeting}
        isRequired={true}
        onChange={handleOnChange}
        options={voteChoices.map((choice) => ({
          label: capitalize(choice).replaceAll("_", " "),
          value: choice,
          disabled: isPassedCutoffDate || isAttendingMeeting,
          isRecommended: choice === recommendation,
          isRecommendedByContestingParty:
            choice === contestingPartyRecommendation,
        }))}
        validate={validate}
      />
      {!!groupMaximumVotes && currentValue && (
        <Stack direction="row" alignItems="center" sx={{ mt: 2 }}>
          {maxVoteError ? (
            <Error
              sx={{
                mr: 2,
                width: "16px",
                color: maxVoteError ? COLORS.ERROR_DARK : theme.palette.l1.main,
              }}
            />
          ) : (
            <CheckCircle
              sx={{
                mr: 2,
                width: "16px",
                color: maxVoteError ? COLORS.ERROR_DARK : theme.palette.l1.main,
              }}
            />
          )}
          <Text
            variant="body3"
            sx={{
              color: maxVoteError ? COLORS.ERROR_DARK : theme.palette.l4.main,
            }}
          >
            {`${inFavors.length}/${groupMaximumVotes} "${capitalize(
              voteChoices[0]
            )}" voted`}
          </Text>
        </Stack>
      )}
    </Box>
  );
}
