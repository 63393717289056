import { COLORS, Text } from "@asayinc/component-library";
import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  SxProps,
  useTheme,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

/*
  CustomFormRadioGroup is temporary. We should update the HookRadioButton
  in the component library, update the FormRadioGroup, and start using that
  instead.
*/

type RadioGroupOptions = {
  label: string;
  value: string;
  disabled?: boolean;
  isRecommended?: boolean;
  isRecommendedByContestingParty?: boolean;
};

interface ICustomFormRadioGroupProps {
  isDisabled?: boolean;
  isRequired?: boolean;
  isVertical?: boolean;
  name: string;
  onChange?: () => void;
  options: RadioGroupOptions[];
  sx?: SxProps;
  validate?: () => boolean;
}

export function CustomFormRadioGroup({
  isDisabled,
  isRequired,
  isVertical,
  name,
  onChange,
  options,
  sx,
  validate,
}: ICustomFormRadioGroupProps) {
  const { control, watch } = useFormContext();
  const theme = useTheme();
  const value = watch(name);

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: isRequired,
        validate: {
          customValidation: () => {
            return validate ? validate() : true;
          },
        },
      }}
      render={({ field }) => {
        return (
          <RadioGroup
            {...field}
            row
            onChange={(_, value) => {
              field.onChange(value);
              onChange && onChange();
            }}
            value={field.value}
            sx={{
              flexGrow: 1,
              display: "flex",
              justifyContent: "space-between",
              mt: 3,
              gap: isVertical ? "8px" : "12px",
              flexDirection: isVertical ? "column" : "row",
              [theme.breakpoints.down("md")]: {
                flexDirection: "column",
              },
              ...sx,
            }}
          >
            {options.map((option) => {
              const isChecked = value === option.value;
              const recommendedBy =
                option.isRecommended && option.isRecommendedByContestingParty
                  ? "both"
                  : option.isRecommended
                  ? "management"
                  : "dissident";
              return (
                <FormControlLabel
                  sx={{
                    border: `1px solid ${
                      isChecked ? theme.palette.l4.main : "#E0E0E0"
                    }`,
                    borderRadius: "8px",
                    display: "flex",
                    alignItems: "center",
                    px: 2,
                    flexGrow: 1,
                    py: 1,
                    mx: 0,
                    flexBasis: 0,
                    ".MuiFormControlLabel-label": {
                      width: "100%",
                    },
                  }}
                  disabled={isDisabled}
                  key={option.value}
                  value={option.value}
                  control={
                    <Radio
                      size="small"
                      sx={{
                        color: isChecked ? theme.palette.l4.main : undefined,
                        "&, &.Mui-checked": {
                          color: isChecked
                            ? `${theme.palette.l4.main}`
                            : "#E0E0E0",
                        },
                        padding: "6px",
                      }}
                    />
                  }
                  label={
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <Text
                        sx={{
                          color: isDisabled
                            ? COLORS.P_OUTLINE
                            : isChecked
                            ? theme.palette.l4.main
                            : COLORS.BLACK,
                          flexGrow: 1,
                        }}
                        variant="body2"
                      >
                        {option.label}
                      </Text>
                      {(option.isRecommended ||
                        option.isRecommendedByContestingParty) && (
                        <Text
                          sx={{
                            color: theme.palette.l4.main,
                            mx: 3,
                            textAlign: "end",
                          }}
                          variant="body3"
                        >
                          Recommended by {recommendedBy}
                        </Text>
                      )}
                    </Box>
                  }
                />
              );
            })}
          </RadioGroup>
        );
      }}
    />
  );
}
